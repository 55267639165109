.p_next {
  width: max-content;
  margin-left: auto;
  &-text {
    margin-right: 20px;
    font-size: 16px;
    display: block;
  }
  &-arrow {
    width: 50px;
    display: block;
  }
}