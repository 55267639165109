.c_globalNav {
  margin-bottom: 20px;
  @include mq(m) {
    padding: 0 5%;
    margin-bottom: auto;
  }
  &-list {
    justify-content: right;
    @include mq(m) {
      display: block;
    }
    &-item {
      margin-left: 20px;
      a {
        opacity: 1;
        transition: 0.5s all var(--transition);
        &:hover {
          opacity: .5;
        }
      }
      @include mq(m) {
        margin-left: 0;
        margin-bottom: 20px;
      }
    }
  }
}