@charset "utf-8";
* {
  margin: 0;
  padding: 0;
}
main {
  display: block;
}
html {
  height: 100%;
  -webkit-text-size-adjust: 100%;
}
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
p,
q,
samp,
small,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
body {
  font-size: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-weight: 400;
  width: 100%;
  height: 100%;
  line-height: 1;
}
img {
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  border: none;
  vertical-align: bottom;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
nav {
  display: block;
}
ul,
li {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
a {
  outline: none;
  text-decoration: none;
  color: inherit;
}
a:focus {
  outline: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
th {
  font-weight: normal;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}
input,
select {
  vertical-align: middle;
   -webkit-appearance: none;
  appearance: none;
  color: inherit;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}
* html .clearfix {
  height: 1px;
  /*¥*/ /*/
  height: auto;
  overflow: hidden;
  /**/
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
  font-size: inherit;
  color: inherit;
  font-family: inherit;
  line-height: 1;
  letter-spacing: inherit;
}
svg {
  width: 100%;
  height: 100%;
}
address {
  font-style: normal;
}
