.c_navigation {
  @include mq(m) {
    margin-bottom: 40px;
    margin-top: auto;
  }
  &-list {
    justify-content: right;
    font-size: 16px;
    @include mq(m) {
      display: block;
      border-top: 1px solid var(--line-color);
    }
    &-item {
      margin-left: 35px;
      &:first-child {
        margin-left: 0;
      }
      @include mq(m) {
        margin: 0;
        border-bottom: 1px solid var(--line-color);
        a {
          display: block;
          padding: 20px 5% 30px 5%;
        }
      }
    }
  }
  &-link {
    padding: 30px 0;
    display: block;
    position: relative;
    margin-top: 7px;
    cursor: pointer;
  }
}