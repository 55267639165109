@mixin linePost {
  background-image: linear-gradient(#000000, #000000);
  background-position: left 100%;
  background-size: 100% 1px;
  background-repeat: no-repeat;
  transition: background-size 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
@mixin linePostRed {
  background-image: linear-gradient(#DC000C, #DC000C);
  background-position: left 100%;
  background-size: 100% 1px;
  background-repeat: no-repeat;
  transition: background-size 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
@mixin linePostWhite {
  background-image: linear-gradient(#ffffff, #ffffff);
  background-position: left 100%;
  background-size: 100% 1px;
  background-repeat: no-repeat;
  transition: background-size 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
@mixin linePostHover {
  background-position: right 100%;
  background-size: 0 1px;
}

@mixin lineAfter{
  position: absolute;
  left: 0;
  bottom: -3px;
  background: black;
  content: "";
  width: 100%;
  height: 1px;
  z-index: 2;
  transform-origin: left;
  transition: transform 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
}
@mixin lineAfterRed{
  position: absolute;
  left: 0;
  bottom: -3px;
  background: var(--main-color);
  content: "";
  width: 100%;
  height: 1px;
  z-index: 2;
  transform-origin: left;
  transition: transform 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
}

@mixin lineAfterwhite{
  position: absolute;
  left: 0;
  bottom: -3px;
  background: white;
  content: "";
  width: 100%;
  height: 1px;
  z-index: 2;
  transform-origin: left;
  transition: transform 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
}

@mixin lineAfterHover{
  transform: scaleX(0);
  transform-origin: right;
}
@mixin lineAnime {
  z-index: 2;
  content: "";
  width: 100%;
  height: 1px;
  background:black;
  position: absolute;
  bottom: 0;
  left: -100%;
  animation: anim-out .6s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  @keyframes anim-out {
    0% {left:0}
    100% {left:100%;}
  }
}
@mixin lineAnimeRed {
  z-index: 2;
  content: "";
  width: 100%;
  height: 1px;
  background:var(--main-color);
  position: absolute;
  bottom: 0;
  left: -100%;
  animation: anim-out .6s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  @keyframes anim-out {
    0% {left:0}
    100% {left:100%;}
  }
}
@mixin lineAnimeWhite {
  z-index: 2;
  content: "";
  width: 100%;
  height: 1px;
  background:white;
  position: absolute;
  bottom: 0;
  left: -100%;
  animation: anim-out .6s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  @keyframes anim-out {
    0% {left:0}
    100% {left:100%;}
  }
}
@mixin lineAnimeHover {
  animation: anim .5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  @keyframes anim {
    0% {left:-100%;}
    100% {left:0;}
  }
}