@use "sass:math";

:root {
  --text-color: #{map-get(map-get($font, color), text)};
  --a-color: #{map-get(map-get($font, color), a)};
  --main-color: #{map-get($color, main)};
  --bg-color: #{map-get($color, bg)};
  --line-color: #{map-get($color, line)};
  --border: #{map-get($color, border)};
  --transition: cubic-bezier(0.165, 0.84, 0.44, 1);
}

body {
  position: relative;
  font-family: #{map-get(map-get($font, family), default)};
  color: map-get(map-get($font, color), text);
  letter-spacing: 0.1em;
  font-size: 14px;
  min-width: 1000px;
  @include mq(m) {
    min-width: inherit;
  }
}