.p_button {
  width: max-content;
  overflow: hidden;
  position: relative;
  @include responsive-padding-bottom(10px,20px);
  span {
    display: block;
    @include responsive-font-size(14px,28px);
  }
  &:after {
    display: block;
    width: 20px;
    @include responsive-width(20px,30px);
    content: "";
    @include responsive-height(2px,4px);
    background: black;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: 0.5s all var(--transition);
  }
  &:hover {
    display: block;
    &:after {
      width: 100%;
    }
  }
  &__lrage {
    @include responsive-padding-bottom(15px,30px);
    span {
      @include responsive-font-size(18px,36px);
    }
  }
  &__white {
    color: white;
    &:after {
      background: white;
    }
  }
}
