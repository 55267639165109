.c_conversion {
  &-list {
    width: 50%;
    position: relative;
    @include mq(m) {
      width: 100%;
    }
    a {
      display: block;
    }
    &-images {
      position: relative;
      overflow: hidden;
      &:after {
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        background: #000000;
        opacity: .3;
      }
    }
    &-view {
      z-index: 4;
      color: white;
      width: max-content;
      .jp {
        display: block;
        font-size: 18px;
        margin-bottom: 20px;
        @include mq(m) {
          font-size: 16px;
          margin-bottom: 10px;
        }
      }
      .en {
        display: block;
        font-size: 45px;
        margin-bottom: 30px;
        @include mq(m) {
          font-size: 28px;
          margin-bottom: 15px;
        }
      }
    }
  }
}