.c_openNav {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 994;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s all var(--transition);
  flex-direction: column;
  justify-content: center;
  overflow: scroll;
  color: white;
  background: rgba(0,0,0,0.5);
  &[data-expanded="true"] {
    opacity: 1;
    pointer-events: auto;
    .c_openNav-navigation {
      transform: translate3d(0, 0, 0);
    }
  }
  &-close {
    @include responsive-width(100px,200px);
    @include responsive-height(100px,200px);
    background: white;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9;
    img {
      @include responsive-width(30px,60px);
    }
  }
  &-body {
    position: relative;
    z-index: 2;
    width: get_vw(845px, xxl);
    background:#272727;
    flex-direction: column;
    margin-left: auto;
    @include mq(m) {
      width: 100%;
    }
  }
  &-wrapper {
    @include responsive-padding-top(155px,335px);
    @include responsive-padding-right(130px,128px);
    @include responsive-padding-bottom(90px,160px);
    @include responsive-padding-left(130px,128px);
  }
  &-headline {
    width: 50%;
    @include mq(m) {
      width: 100%;
    }
  }
  &-nav {
    width: 50%;
    @include mq(m) {
      width: 100%;
    }
  }
  &-list {
    @include responsive-margin-bottom(70px,150px);
  }
  &-item {
    @include responsive-margin-bottom(20px,50px);
    &:last-child {
      margin-bottom: 0;
    }
    a {
      color: white;
      align-items: center;
      width: max-content;
      opacity: 1;
      transition: 0.5s all var(--transition);
      &:hover {
        opacity: .4;
      }
      &:before {
        @include responsive-width(10px,20px);
        height: 2px;
        background: white;
        display: block;
        content: "";
        @include responsive-margin-right(20px,40px);
      }
      span {
        display: block;
        @include responsive-font-size(14px,32px);
      }
    }
  }
  &-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

body {
  &.isActive {
    overflow: hidden;
  }
}