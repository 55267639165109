.c {
  &_header {
    padding: 30px 50px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    color: white;
    align-items: center;
    background: inherit;
    transition: 0.5s all var(--transition);
    min-width: 1000px;
    @include mq(m) {
      min-width: inherit;
      padding: 20px;
      &[data-expanded="true"] {
        .c_header-navigation {
          opacity: 1;
          pointer-events: all;
        }
      }
    }
    &.isStart {
      background: white;
      .c_header {
        &-logo {
          .on {
            opacity: 0;
          }
          .off {
            opacity: 1;
          }
        }
        &-navigation {

        }
        &-normal {
          color: black;
          a {
            span {
              background-image: linear-gradient(#000000, #000000);
            }
          }
        }
        &-globalNav {
          color: black;
        }
      }
      .c_hamburger-list-item {
        background: black;
      }
    }
    &-logo {
      position: relative;
      width: 270px;
      margin-right: auto;
      @include mq(m) {
        width: 160px;
      }
      span {
        transition: 0.5s all var(--transition);
      }
      .on {
        opacity: 1;
      }
      .off {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    &-navigation {
      margin-left: auto;
      width: calc(100% - 270px);
      @include mq(m) {
        display: flex;
        flex-direction: column-reverse;
        background: white;
        width: 100%;
        height: calc(100% - 58px);
        position: fixed;
        top: 58px;
        left: 0;
        color: black;
        padding: 50px 0;
        overflow: scroll;
        margin: 0;
        opacity: 0;
        pointer-events: none;
        transition: 0.5s all var(--transition);
      }
    }
  }
}
