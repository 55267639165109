$font: (
  size: (
    pc: 14px,
    // spはvwに変換される
    sp: 28px,
  ),
  color: (
    text: black,
    a: black,
  ),
  family: (
    default: (
      "YakuHanJP",
      "Noto Sans JP",
      sans-serif,
    ),
    en: (
      "Zen Kaku Gothic New",
      sans-serif,
    ),
  ),
);

$breakpoints: (
  xxl: 1300px,
  xl: 1260px,
  l: 1040px,
  m: 768px,
  s: 414px,
);

//.t_txtのline-height
$lineHeight: 1.8;

// ここまで必須、以下自由定義

$color: (
  main: #F4F4F6,
  bg: #E4E4E6,
  line: #D6D6D6,
  border:#707070,
);
