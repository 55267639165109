.c {
  &_footer {
    padding: 70px 0 20px 0;
    letter-spacing: 0;
    @include mq(m) {
      padding: 40px 0;
    }
    &-layout {
      margin-bottom: 80px;
      @include mq(m) {
        margin-bottom: 40px;
        display: none;
      }
    }
    &-logo {
      width: 385px;
      @include mq(m) {
        width: 100%;
        margin-bottom: 40px;
      }
      a {
        display: block;
        width: 260px;
        @include mq(m) {
          width: 160px;
        }
      }
    }
    &-navi {
      width: calc(100% - 385px);
      justify-content: space-between;
      @include mq(m) {
        width: 100%;
        display: block;
      }
    }
    &-list {
      &-item {
        font-size: 12px;
        margin-bottom: 20px;
      }
    }
    &-address {
      align-items: center;
      font-size: 12px;
      justify-content: center;
      margin-bottom: 15px;
      @include mq(m) {
        display: block;
        margin-bottom: 40px;
      }
      &-list {
        margin: 0 5px;
        @include mq(m) {
          margin: 0 0 20px 0;
        }
      }
    }
    &-copy {
      font-size: 12px;
      display: block;
      text-align: center;
    }
  }
}