.p_title {
  @include mq(m) {
    margin-bottom: 30px;
  }
  &-jp {
    display: block;
    font-size: 18px;
    margin-bottom: 20px;
    @include mq(m) {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  &-en {
    display: block;
    font-size: 45px;
    @include mq(m) {
      font-size: 28px;
    }
  }
}