@use "sass:math";

.t_wrapper {
  width: 1000px;
  margin: 0 auto;
  &-aside {
    width: 370px;
    @include mq(m) {
      width: 100%;
    }
  }
  &-contents {
    width: calc(100% - 370px);
    @include mq(m) {
      width: 100%;
    }
  }
  @include mq(m) {
    width: 90%;
  }
}
