.c_banner {
  overflow: hidden;
  padding: 100px 0;
  &-list {
    &-item {
      width: 31.33%;
      margin: 0 3% 0 0;
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
      @include mq(m) {
        width: 100%;
        margin: 0 0 20px 0;
      }
      &:nth-child(3n) {
        margin: 0;
      }
      a {
        width: 100%;
        padding: 40px 20px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        text-align: center;
        height: 100%;
        background: white;
        transition: 0.5s all var(--transition);
        @include mq(m) {
          padding: 20px;
          min-height: 140px;
        }
        &:hover {
          background: #2E2E2E;
          color: white;
          .logo {
            .on {
              opacity: 0;
            }
            .off {
              opacity: 1;
            }
          }
        }
        .margin {
          margin-bottom: 12px;
        }
        i {
          font-size: 14px;
          position: absolute;
          right: 20px;
          bottom: 20px;
          @include mq(m) {
            font-size: 12px;
          }
        }
        .logo {
          width: 190px;
          position: relative;
          span {
            display: block;
            transition: 0.5s all var(--transition);
          }
          .on {
            display: block;
            opacity: 1;
          }
          .off {
            display: block;
            opacity: 0;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
          }
        }
        .large {
          font-size: 20px;
          @include mq(m) {
            font-size: 16px;
          }
        }
      }
    }
  }
}