.u_letter-spacing {
  &10 {
    letter-spacing: 0.01em;
  }
  &50 {
    letter-spacing: 0.05em;
  }
  &100 {
    letter-spacing: 0.1em;
  }
  &150 {
    letter-spacing: 0.17em;
  }
  &200 {
    letter-spacing: 0.22em;
  }
}
.u_weight {
  &100 {
    font-weight: 400;
  }
  &500 {
    font-weight: 500;
  }
  &700 {
    font-weight: 700;
  }
}

.u_vertical {
  writing-mode: vertical-lr;
}

.u_lineHeight {
  line-height: 2;
  @include mq(m) {
    line-height: 1.8;
  }
  &Max {
    line-height: 3.25;
    @include mq(m) {
      line-height: 2;
    }
  }
  &Title {
    line-height: 1.6;
    @include mq(m) {

    }
  }
}

.u_center {
  text-align: center;
}
.u_right {
  text-align: right;
}

.u_bg {
  background: #272727;
}

.u_white {
  color: white;
}

.u_md {
  display: none;
  @include mq(m) {
    display: block;
  }
}

.u_desktop {
  @include mq(m) {
    display: none;
  }
}