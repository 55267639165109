.c {
  &_hamburger {
    width: 38px;
    height: 18px;
    cursor: pointer;
    transition: 0.5s all var(--transition);
    display: none;
    @include mq(m) {
      display: block;
    }
    &:hover {
      .c_hamburger-list-item {
        &:nth-child(2n) {
          width: 80%;
        }
      }
    }
    &[data-expanded="true"] {
      .c_hamburger-list {
      }
      .c_hamburger-list-item {
        &:first-child {
          transform:rotate(-25deg);
          top: 9px;
        }
        &:nth-child(2n) {
          width: 0;
        }
        &:last-child {
          transform:rotate(25deg);
          top: -9px;
        }
      }
      .c_hamburger-text {
        .on {
          opacity: 1;
        }
        .off {
          opacity: 0;
        }
      }
    }
    &-list {
      width: 100%;
      position: relative;
      &-item {
        width: 100%;
        height: 2px;
        margin-bottom: 7px;
        background: white;
        transition: 0.5s all var(--transition);
        position: relative;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}