.p_hover {
  &:hover {
    .p_view-text {
      &:after {
        @include lineAfterHover;
      }

    }
    .p_hover-button {
      &:after {
        width: 100%;
      }
    }
    .p_hover-inline {
      @include linePostHover;
    }
    .p_hover-images {
      img {
        transform:scale(1.2);
      }
    }
    .p_hover-view {
      span {
        &:before {
          @include lineAnimeHover;
        }
      }
    }
    .p_hover-color {
      color: var(--main-color);
    }
    .p_hover-next {
      &-arrow {
        transform: translateY(0) translateX(10px);
      }
    }
  }
  &-inline {
    @include linePost;
    padding: 0 0 5px 0;
  }
  &__red {
    .p_hover-inline {
      @include linePostRed;
    }
    .p_hover-view {
      span {
        &:before {
          @include lineAnimeRed;
        }
      }
    }
  }
  &__white {
    .p_hover-inline {
      @include linePostWhite;
    }
    .p_hover-view {
      span {
        &:before {
          @include lineAnimeWhite;
        }
      }
    }
  }
  &-images {
    position: relative;
    overflow: hidden;
    img {
      transform:scale(1);
      transition: 0.5s all var(--transition);
    }
  }
  &-view {
    span {
      padding-bottom: 10px;
      position: relative;
      overflow: hidden;
      display: inline-block;
      &:before {
        @include lineAnime;
      }
    }
  }
  &-color {
    transition: 0.5s all var(--transition);
  }
  &-next {
    &-arrow {
      transition: 0.5s all var(--transition);
      transform: translateY(0) translateX(0);
    }
  }
}