.p_evenly {
  margin-bottom: 70px;
  align-items: end;
  @include mq(m) {
    margin-bottom: 30px;
  }
  &-parent {
    margin-right: auto;
    &__title {
      margin-bottom: 0;
    }
  }
  &-child {
    margin-left: auto;
  }
}