.c_sns {
  padding: 50px 0;
  border-top: 1px solid var(--line-color);
  border-bottom: 1px solid var(--line-color);
  &-list {
    align-items: center;
    justify-content: center;
    &-item {
      margin: 0 5px;
      a {
        display: block;
        span {
          display: block;
          width: 70px;
        }
      }
    }
  }
}