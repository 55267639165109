@use "sass:math";

#HOME-HERO .mask {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 28px;
}

.indexMv {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 2;
  &_images {
    width: 100%;
    height: 100%;
    img {
      @include objectfit;
    }
  }
  &_headline {
    width: 540px;
    position: absolute;
    right: 50px;
    bottom: get_vw(260px, xxl);
    @include mq(m) {
      width: 60%;
      right: 20px;
    }
  }
  &_title {
    position: absolute;
    width: 100%;
    z-index: 3;
    left: 0;
    bottom: 0;
  }
  @include mq(m) {
  }
}

.indexPickUp {
  background: var(--main-color);
  padding: 120px 0 150px 0;
  overflow: hidden;
  margin-bottom: 130px;
  @include mq(m) {
    padding: 60px 0;
    overflow: hidden;
    margin-bottom: 60px;
  }
  &_slider {
    padding-right: 130px;
    @include mq(m) {
      padding-right: 40px;
    }
    &-list {
      width: 390px;
      @include mq(m) {
        width: 50%;
      }
      a {
        &:hover {
          .images {
            &:after {
              opacity: 0.3;
            }
          }
        }
      }
      .images {
        margin-bottom: 20px;
        position: relative;
        &:after {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background: black;
          content: "";
          z-index: 1;
          opacity: 0;
          transition: 0.5s all var(--transition);
        }
      }
      &-view {
        @include mq(m) {
          font-size: 12px;
        }
      }
    }
  }
}

.indexProducts {
  overflow: hidden;
  &_list {
    &-item {
      padding-left: calc((100% - 1000px) / 2);
      @include mq(m) {
        padding: 0;
      }
      a {
        align-items: center;
        opacity: 1;
        transition: 0.5s all var(--transition);
        width: 100%;
        @include mq(m) {
          flex-direction: column;
        }
        &:hover {
          opacity: 0.5;
        }
      }
      &:nth-child(2n) {
        padding-left: 0;
        background: var(--bg-color);
        padding-right: calc((100% - 1000px) / 2);
        a {
          flex-direction: row-reverse;
          @include mq(m) {
            flex-direction: column;
          }
        }
        @include mq(m) {
          padding: 0;
        }
      }
      .view {
        position: relative;
        width: calc(100% - 805px);
        text-align: center;
        @include mq(m) {
          width: 100%;
          padding: 100px 20px 0 20px;
        }
        .number {
          z-index: 1;
          opacity: 0.05;
          font-size: 190px;
          width: 100%;
          text-align: center;
          white-space: nowrap;
          @include mq(m) {
            font-size: 100px;
          }
        }
        .title {
          .jp {
            font-size: 25px;
            margin-bottom: 15px;
            @include mq(m) {
              font-size: 18px;
              margin-bottom: 10px;
            }
          }
          .en {
            font-size: 16px;
            @include mq(m) {
              font-size: 14px;
            }
          }
        }
      }
      .images {
        width: 805px;
        @include mq(m) {
          width: 100%;
        }
      }
    }
  }
}

.indexData {
  background: var(--main-color);
  padding: 160px 0 190px 0;
  overflow: hidden;
  @include mq(m) {
    padding: 80px 0;
  }
  &_list {
    &-item {
      margin-bottom: 50px;
      @include mq(m) {
        margin-bottom: 30px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      a {
        position: relative;
        width: 100%;
        display: block;
        padding-bottom: 50px;
        border-bottom: 1px solid var(--line-color);
        &:hover {
          .icon {
            opacity: 0.3;
          }
          .title {
            opacity: 0.3;
          }
        }
        @include mq(m) {
          padding-bottom: 30px;
        }
        &:hover {
        }
        .icon {
          width: 60px;
          display: block;
          transition: 0.5s all var(--transition);
          opacity: 1;
          @include mq(m) {
            width: 40px;
          }
        }
        .title {
          width: calc(100% - 60px);
          padding-left: 35px;
          transition: 0.5s all var(--transition);
          opacity: 1;
          @include mq(m) {
            width: calc(100% - 40px);
            padding-left: 20px;
          }
          .jp {
            display: block;
            margin-bottom: 15px;
            font-size: 18px;
            @include mq(m) {
              margin-bottom: 10px;
              font-size: 16px;
            }
          }
          .en {
            display: block;
            font-size: 16px;
            @include mq(m) {
              font-size: 14px;
            }
          }
        }
        .box {
          position: relative;
          align-items: center;
        }
        .next {
          position: absolute;
          right: 0;
          bottom: 0;
          font-size: 18px;
          @include mq(m) {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.indexNews {
  background: var(--main-color);
  padding-bottom: 170px;
  @include mq(m) {
    padding-bottom: 100px;
  }
  &_list {
    overflow: hidden;
    margin-bottom: 40px;
    @include mq(m) {
      margin-bottom: 30px;
    }
    &-item {
      padding-bottom: 30px;
      border-bottom: 1px solid var(--line-color);
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        display: block;
        opacity: 1;
        transition: 0.5s all var(--transition);
        &:hover {
          opacity: 0.3;
        }
      }
      .box {
        .information {
          color: #707070;
          margin-bottom: 10px;
          font-size: 12px;
          time {
            display: block;
            margin: 0 10px 0 0;
          }
          .category {
            display: block;
          }
        }
      }
    }
  }
}
